import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/dragon/cat-sucursales', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/dragon/cat-sucursales/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/dragon/cat-sucursales', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
  updateUser(ctx, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/dragon/cat-sucursales/${id}`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        });
    })
  },
  deleteRecord(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/dragon/cat-sucursales/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        });
    })
  }

}
